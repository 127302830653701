import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    redirect:'/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
   
  },
  {
    path: '/about',
    name: 'About',
    component: ()=>import("../views/About.vue")
  },
  {
    path: '/projects',
    name: 'Projects',
    component:  ()=>import("../views/projects.vue")
  },
  {
    path: '/metaCoin',
    name: 'MetaCoin',
    component:  ()=>import("../views/metaCoin.vue")
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component:  ()=>import("../views/announcements.vue")
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
