<template>
  <div id="app">
    <!--这里做一个空盒子用来帮助顶部导航栏滑动时用-->
    <div class="headeWrap"></div>
      <div class="header">
        <div class="left">
            <router-link class="link" to='Home'>
              <div class="logo"><img src="./assets/img/logo_omf@2x.png" alt=""></div>
            <!-- <div>Open</div>
            <div>MetaNet</div>
            <div>Foundation</div> -->
            </router-link >
          <!-- <div class="title">
     
          </div> -->
        </div>
        <div class="right" v-if='!collspan'>
          <div class="line">
            <router-link class="link" to='MetaCoin'><div  class="toTarget">MetaCoin</div></router-link>
          </div>
            <div class="line">
            <router-link class="link" to='Announcements'><div  class="toTarget">Announcements</div></router-link>
          </div>
          <div class="line">
            <router-link class="link " to='About'>
            <div class="toTarget">About</div>
            </router-link>
          </div>
          <div class="line">
            <router-link class="link" to='Projects'><div  class="toTarget">Projects</div></router-link>
          </div>
        </div>
        <div class="collspan" v-else>
          <i class="iconfont icon-zhedie" @click.stop="toggleCollspan"></i>
          <div class="collsWrap" v-if='toast' @click="toggleCollspan">
          <div class="line">
            <router-link class="link" to='MetaCoin'><div  class="toTarget">MetaCoin</div></router-link>
          </div>
            <div class="line">
            <router-link class="link" to='Announcements'><div  class="toTarget">Announcements</div></router-link>
          </div>
          <div class="line">
            <router-link class="link " to='About'>
            <div class="toTarget">About</div>
            </router-link>
          </div>
          <div class="line">
            <router-link class="link" to='Projects'><div  class="toTarget">Projects</div></router-link>
          </div>
          </div>
        </div>
        
      </div>

       <div class="contentWrap">
        <transition :enter-active-class="enterClass" appear>
        <router-view class="animated"></router-view>
        </transition>
        
  

        </div>
     
     
    

  </div>
</template>
<script>

export default {
  name:"App",
  data(){
    return{
      enterClass:"animated fadeInUp ",
      collspan:false,
      deviceWidth:null,
      toast:false
    }
  },
  watch:{
    deviceWidth:{
      handler(newVal){
        // console.log(newVal)
        if(newVal<980){
          this.collspan=true
        }
      },
     
    }
  },
  created(){
   this.deviceWidth=document.body.clientWidth
  },

  methods:{
    toggleCollspan(){
      this.toast=!this.toast
    },
    toMetaID(){
      window.open('https://www.metaid.io')
    },
    toMetaProtocols(){
      window.open('https://www.metaprotocols.com')
    },
   
  }
}
</script>
<style lang="scss">
html
body{
  padding: 0;
  margin: 0;
  font-size: 14px;
  background-color: rgb(244,252,255);
  position: relative;
  font-family: SF Pro Medium !important;
  background: url("./assets/img/bg.png") no-repeat ;
  background-size:cover;
  background-attachment: fixed;
  overflow-y: scroll;
}
#app {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  
  // height: 100vh;
  display: flex;
  flex-direction: column;
  .headeWrap{
    width: 100vw;
    height:4.285714rem /* 60/14 */;
    background-color: rgb(238,250,255);;
    z-index: 999;
    position: fixed;
    top: 0;
    font-family:PingFangSC-Regular, sans-serif;
  }
  .header{
   top: 0;
    z-index: 999;
    position: fixed;
    width: 90vw;
    display: flex;
    padding-top: 1.5rem /* 21/14 */;
    justify-content: space-between;
    font-size: 1.142857rem /* 16/14 */;
    //  font-family: Bangla MN;
    
   
    .left{
    
      padding-left: 8rem /* 112/14 */;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo{
        width: 16.5rem /* 231/14 */;
        height: 4.214286rem /* 59/14 */;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        height: 4.285714rem /* 60/14 */;
        padding-left: .571429rem /* 8/14 */;
        display: flex;
        flex-direction: column;
        color: #101315;
        cursor: pointer;
       
        .link{
           &:hover{
            color:  #3182F9;
        }
        }
        div{
          font-family:PingFangSC-Regular, sans-serif;
         flex: 1;
         line-height: 1.5rem /* 21/14 */;
        }
       
      }
    }
    .right{
      // width: 10.714286rem /* 150/14 */;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family:PingFangSC-Regular, sans-serif;
      // margin-right:10.714286rem /* 150/14 */;
      .line{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 2.857143rem /* 40/14 */;
        .link{
           .toTarget{
            color:  #101315;
            font-size: 1.142857rem /* 16/14 */;
            
              position: relative;
              &::after{
                content:"";
                position:absolute;
                top: 100%;
                margin-top: .214286rem /* 3/14 */;
                left: 50%;
                width:0;
                height: .142857rem /* 2/14 */;
                transition: all 0.4s;
                background: #101315;
              }
              &:hover{
                &::after{
                  left: 0%;
                  width: 100%;
                }
                
              }
          }
         
        }
      }
    }
  }

}
@media screen and(max-width:1500px){
  #app {
  .footer{
    .footerLeft{
      width: 40vw;
   
    }
  }
}
}
@media screen and(max-width:1200px){
  #app {
  .footer{
    .footerLeft{
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div{
        height: 1.785714rem /* 25/14 */;
        line-height: 1.785714rem /* 25/14 */;
      }
   
    }
  }
}
}
@media screen and(max-width: 980px){
#app{
    .header{
      .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .line{
          margin-bottom: .714286rem /* 10/14 */;
        }
      }
    }
    .footer{    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
 
}
}
@media screen and(max-width: 768px){
#app{

  .header{
    width: 100vw;
    .left{
     padding-left: 2.142857rem /* 30/14 */;
    }
  }
  .footer{
    padding-left: 0;
  }
}
}
@media screen and(max-width: 500px){
  #app {
  overflow-x: hidden;
  background-size:cover;
  background: url("./assets/img/phoneBg.png") no-repeat ;
  background-size:cover;
  background-attachment: fixed;

  .header{
    padding: .714286rem /* 10/14 */ 0;
    width: 100vw;
    
    justify-content: space-between;
    .left{
      padding-left: .714286rem /* 10/14 */;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo{
        width:6.428571rem /* 90/14 */;
        height: 1.785714rem /* 25/14 */;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        height: 2.857143rem /* 40/14 */;
        display: flex;
        flex-direction: column;
        color: #101315;
        cursor: pointer;
          .link{
           &:hover{
            color: #3182F9;
        }
        }
        div{
         flex: 1;
         line-height:1.1em;
         font-size: .785714rem /* 11/14 */;
        }
       
      }
    }
    .right{
       display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      .line{
        .link{
          margin-right: .142857rem /* 2/14 */;
           .toTarget{
            
              position: relative;
              &::after{
                content:"";
                position:absolute;
                top: 100%;
                margin-top: .214286rem /* 3/14 */;
                left: 50%;
                width:0;
                height: .142857rem /* 2/14 */;
                transition: all 0.4s;
                background: #101315;
              }
              &:hover{
                &::after{
                  left: 0%;
                  width: 100%;
                }
                
              }
          }
         
        }
      }
    }
  .collspan{
    display: flex;
    align-items: center;
    margin-right: 1.071429rem /* 15/14 */;
    position: relative;
    i{
      font-size: 1.785714rem /* 25/14 */;

    }
    .collsWrap{
      position: absolute;
      top:2.5rem /* 35/14 */;
      right: 0;
      // width: 3.571429rem /* 50/14 */;
      // height: 3.571429rem /* 50/14 */;
      border:1px solid rgba($color: #3182F9, $alpha: 0.2);
      box-shadow: 0px 0px 3px 3px rgba($color: #3182F9, $alpha: 0.3);
      background-color:#F5F7FA;
      border-radius: .357143rem /* 5/14 */;
      z-index: 999;
      
      .line{
        display: flex;
        align-items: center;
        justify-content: center;
       
        // margin-top:1.071429rem /* 15/14 */;
        // text-align: center;
        padding: 1.071429rem /* 15/14 */.214286rem /* 3/14 */;
        border-bottom: 1px solid rgba($color: #3182F9, $alpha: 0.3);
        .toTarget{
          font-size: 1.285714rem /* 18/14 */;
        }
      }
      // .line:nth-child(4){
      //   margin-bottom: .714286rem /* 10/14 */;
      // }
    }
  }
  }
}

}
</style>
