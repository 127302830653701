<template>
  <div class="home">
    <div class="content">
      <div class="top">MetaNet is the next generation of Internet.</div>
      <div class="bottom">We are committed to boosting the adoption of MetaNet.</div>
    </div> 
      <div class="footer">
        <div class="footerLeft">
          <div @click.stop="toMetaID">MetaID</div>
          <div @click.stop="toMetaProtocols">MetaProtocols</div>
          <div><a target="_blank" href="mailto:showpay@showpay.io">Contact us: metaid@metaid.io</a></div>
        </div>
        <div class="footerRight">
          <div >© 2021 Open MetaNet Foundation, All Rights Reserved.</div>
        </div>
        </div> 
  </div>
</template>
<script>

export default {
  name: 'Home',
  components: {
  
  }
}
</script>
<style lang="scss" scoped>
.home{
   height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
  .content{
    padding-top: 11.428571rem /* 160/14 */;
    padding-left: 8rem /* 112/14 */;
    padding-right: 8rem /* 112/14 */;
    line-height: 5em;
    max-width: 95vw;
    
    .top{
    font-family:PingFangSC-Semibold, sans-serif;
//     font-weight: Semibold;
    color: #101315;
    font-size: 3.428571rem /* 48/14 */;
    }
    .bottom{
    font-family:PingFangSC-Semibold, sans-serif;
//     font-weight: Semibold;
    color: #101315;
    font-size: 3.428571rem /* 48/14 */;
    }
  }
    .footer{
    // height: 5%;
     width: 85vw;
    // height: 5rem /* 70/14 */;
    line-height: 1.5em;
    margin-bottom: .714286rem /* 10/14 */;
    display: flex;
    flex-direction: row;
    
    justify-content: space-between;
    align-items: center;
    padding-left: 8rem /* 112/14 */;
    font-size: 1rem /* 14/14 */;
    font-family: PingFangSC-Regular, sans-serif;
//     font-weight: Regular;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      width: 30vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      justify-content: space-between;
      div{
        margin-right: 2.142857rem /* 30/14 */;
        cursor: pointer;
        &:hover{
          color:  #3182F9;
          opacity: 1;
          filter:alpha(opacity=100);
          -moz-opacity:1;
          -khtml-opacity:1;
        }
        
      }
      div:nth-of-type(3){
        >a{
          &:hover{
              color:  #3182F9;
               opacity: 1;
                filter:alpha(opacity=100);
                -moz-opacity:1;
                -khtml-opacity:1;
          }
        }
      }
    }
    .footerRight{
    
      cursor: pointer;
    }

  }
}
@media screen and (max-width:768px) {
  .home{
  .footer{
    margin-left:2.142857rem /* 30/14 */;
  }
  .content{
    padding-left: 2.142857rem /* 30/14 */;
    padding-right: 8rem /* 112/14 */;
    line-height: 5em;
    max-width: 95vw;
    .top{
    font-family:PingFangSC-Semibold, sans-serif;
//     font-weight: Semibold;
    color: #101315;
    font-size: 3.428571rem /* 48/14 */;
    }
    .bottom{
      font-family:PingFangSC-Semibold, sans-serif;
//         font-weight: Semibold;
    color: #101315;
    font-size: 3.428571rem /* 48/14 */;
    }
  }
}
}
@media screen and(max-width:500px) {
    .home{
     
  .footer{
     margin-left:.714286rem /* 10/14 */;

    line-height:1.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100vw;
    font-size: .928571rem /* 13/14 */;
    font-family: PingFangSC-Regular, sans-serif;
//         font-weight: Regular;
    color: #101315;
    opacity: 0.4;
    filter:alpha(opacity=40);
    -moz-opacity:0.4;
    -khtml-opacity:0.4;
    .footerLeft{
      display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
      // justify-content: flex-start;
    }
    .footerRight{
      padding: 0px;
      cursor: pointer;
      div{
          font-size: .928571rem /* 13/14 */;
      }
    }
  }
  .content{
    
    padding-top: 7.142857rem /* 100/14 */;
    padding-left: .714286rem /* 10/14 */;
    padding-right: 0;
    line-height: 4em;
    text-align: center;
    .top{
      font-size: 2.285714rem /* 32/14 */;
    }
    .bottom{
    text-align: center;
     font-size: 2.285714rem /* 32/14 */;
    }
  
  }
}
}
</style>